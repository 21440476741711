<template>
  <div class="utrPage">
    <!-- <div class="top"> -->
      <!-- <div class="title">Payment Confirm</div> -->
      <!-- <div class="subtl">Please enter 12-digit transaction UTR No.</div> -->
    <!-- </div> -->
    <div class="bk">
      <van-field
      center
        v-model="state.refNo"
        type="number"
        label="UTR :"
        :formatter="formatter"
        maxlength="12"
        :placeholder="state.utrText"
        @click-input="clickIpt"
        @blur="state.utrText = 'Input 12-digital here'"
      >
   <template #button>
         <van-button
            size="small" 
        type="primary"
        :loading="subLoading"
        loading-text="Loading..."
        round
        :class="state.refNo ? '' : ''"
        @click="subOrder"
        >Submit</van-button>
    </template>
  </van-field>
      <img src="@/assets/img/pay/toolfk.jpg" alt="" @click="subOrder" class="sh-img" />
    </div>

    
    <!-- <div class="btn">
      <van-button
        type="primary"
        :loading="subLoading"
        loading-text="Loading..."
        round
        :class="state.refNo ? '' : ''"
        @click="subOrder"
        >Submit Ref Number</van-button
      >
    </div> -->
    <div class="help" @click="state.showUtrPop = true">
      How to find the UTR number ? Click here. >>
    </div>
  </div>
  <van-popup v-model:show="state.showUtrPop" position="center" round>
    <div class="confirmUtrInfo">
      <div class="title">How To Find the UTR No.</div>
      <div class="blocks">
        <div class="tl">Phonepe</div>
        <div class="item">
          <img src="@/assets/img/pay/utr-phonepe.png" alt="" />
        </div>
        <div class="tl">Google pay</div>
        <div class="item">
          <img src="@/assets/img/pay/utr-gpay.png" alt="" />
        </div>
        <div class="tl">Paytm</div>
        <div class="item">
          <img src="@/assets/img/pay/utr-paytm.png" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/pay/utr-paytm2.png" alt="" />
        </div>
        <div class="tl">Amazon Pay</div>
        <div class="item">
          <img src="@/assets/img/pay/utr-amazon.png" alt="" />
        </div>
        <div class="tl">BHIM</div>
        <div class="item">
          <img src="@/assets/img/pay/utr-bhim.png" alt="" />
        </div>
      </div>
    </div>
  </van-popup>
</template>
   
   <script setup>
import { ref, reactive, onMounted, toRefs, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import Axios from "@/axios";
import { Util } from "@/common/js/utils.js";
import { decrypt } from '@/common/js/utils.js'

const route = useRoute();
const router = useRouter();
const subLoading = ref(false);
let state = reactive({
  id: route.query.id, 
  showUtrPop: false,
  refNo: "",
  utrText:'Input 12-digital here'
});

onMounted(async () => {
  //    document.title = 'Dragonpayment'
  //    if(window.globalData !== 'once'){
  //     window.location.href = `${window.location.origin}/copypay?id=${state.id}`
  //    }
});

const formatter = (e) => {

  return e;
};

 const validateInput = (event)  => {
    const input = event.target.value;
    // 只允许数字输入
    const numericInput = input.replace(/\D/g, '');
    // 限制输入内容最大长度为12
    if (numericInput.length > 12) {
      event.target.value = numericInput.slice(0, 12);
    } else {
      event.target.value = numericInput;
    }
    // 更新 state.refNo
    this.state.refNo = event.target.value;
  }

  const clickIpt = () =>{
    state.utrText = ''
  }

const subOrder = async () => {
  if (!state.refNo) {
    return;
  }

  if (
    state.refNo &&
    !["4", "5", "6"].includes(String(state.refNo).substring(0, 1))
  ) {
    return Toast({
      message: "Please enter the correct UTR",
      duration: 2000,
    });
  }

  const isValidRefNo = /^\d{12}$/; // 正则表达式，表示必须是12位数字

  if (!state.refNo || !isValidRefNo.test(state.refNo)) {
    return Toast({
      message: "utr is a 12-digit pure number",
      duration: 2000,
    });
  }

  if (subLoading.value) {
    return;
  }
  subLoading.value = true;
  try {
    let res = await Axios("post", "/safe/submit/utr", {
      id: state.id,
      utr: state.refNo,
    });
    // console.log("res==",res)
    if (res.code === 1) {
      //go waiting page
      window.globalUtrData = {
        subTime: Util.filterTimes(new Date()),
        utr: state.refNo,
      };
      // router.push(`/checkout?id=${state.id}&type=loading`);
    } else {
      Toast({
        message: res.msg,
        duration: 2000,
      });
    }
    subLoading.value = false;
  } catch (error) {
    subLoading.value = false;
  }
};

onUnmounted(() => {});
</script>
   
   <style scoped lang="less">

   @keyframes aniDh {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.3);
  }
}
.utrPage {
  font-size: 14px;
  width: auto;
  background: #efefef;
  padding: 0px;
  padding-bottom: 10px;
  border-radius: 5px;
  .top {
    text-align: center;
    padding-top: 10px;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #129be7;
    }
    .subtl {
      margin-top: 5px;
      font-size: 13px;
      color: #000000;
      font-weight: bold;
    }
  }
  .bk {
    background: #ffffff;
    box-shadow: 0px 4px 6px #cccccc;
    margin: 15px 0;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    :deep(.van-cell) {
      border-radius: 5px;
    }

    .sh-img {
          position: absolute;
          right: 15px;
          bottom: -20px;
          width: 41px;
          height: 41px;
          -webkit-animation-name: aniDh;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
        }

    .iptMy{
        display: flex;
        align-items: center;
        height: 45px;
        .left{
          color: #646566;
          text-align: center;
          font-size: 16px;
          padding: 0px 10px;
        }
        .ipt{

        }
        .ipt::placeholder{
          color: #1989FA;
        }
    }


  }
  .tips {
    color: #000;
    font-size: 12px;
    margin-top: 10px;
  }
  .btn {
    margin-top: 15px;
    :deep(.van-button) {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      &.gray {
        background: #cccccc;
        border: 1px solid #cccccc;
      }
    }
  }
  .help {
    text-align: center;
    margin-top: 15px;
    color: #f43e01;
    font-weight: 600;
    font-size: 13px;
  }
  .bomTips {
    color: #777777;
    text-align: center;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    font-size: 14px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.confirmUtrInfo {
  background: #ffffff;
  position: relative;
  color: #242a32;
  width: 320px;
  background: #ffffff;
  border-radius: 14px;
  padding: 15px;
  max-height: 500px;
  overflow: auto;
  .title {
    color: #045a8a;
    font-weight: 600;
    padding: 15px 0 15px;
    text-align: center;
    font-size: 18px;
  }
  .blocks {
    .tl {
      color: #045a8a;
      font-weight: 600;
      text-align: center;
      font-size: 16px;
      padding: 10px 0;
    }
    .item {
      img {
        width: 100%;
      }
    }
  }
}


::v-deep .van-field__control::-webkit-input-placeholder {
                 text-align: center;
                 color: rgba( 25,137,250);
             }

             ::v-deep .van-field__control{
                text-align: center;
             }

             ::v-deep .van-field__label {
                 position: absolute;
                //  border: 1px solid red;  
             }
             ::v-deep .van-field{
                position: relative;
             }

</style>
   