import axios from 'axios';
import {Toast} from 'vant';

// 拦截器
// 防止重复点击设置
// let cancel, promiseArr = {}
// const CancelToken = axios.CancelToken;
// "请求" 拦截器,过滤重复的请求
// axios.interceptors.request.use(config => {

//     //发起请求时，取消掉当前正在进行的相同请求;
//     if (promiseArr[config.url]) {
//       promiseArr[config.url]('操作取消')
//       promiseArr[config.url] = cancel
//     } else {
//       promiseArr[config.url] = cancel
//     }

//     return config

//   }, error => {

//     return Promise.reject(error)

//   })


const Axios = (method = 'get' ,resourcesName, data,type) => {

    var headerData = {
        'Accept': "application/json; charset=utf-8",
        'appid': "",
        'apiVersion': "1.0",
        'osType': "",
        'deviceId': "",
        'brand': "",
        'dragon-sign': localStorage.getItem('dragonsign') || ''
    };
    let requestUrl = window.baseUrl;
    let requestConfig = (method === 'get' || method === 'GET') ? {
        method: method,
        url: requestUrl + resourcesName,
        params: data,
        headers: headerData
    } : {
        method: method,
        url: requestUrl + resourcesName,
        data: data,
        headers: headerData
    };
    return new Promise((resolve, reject) => {
        console.log("requestConfig==",JSON.stringify(requestConfig))
        axios(requestConfig).then(function (res) {
            resolve(res.data)
           
            console.log('请求结束：',resourcesName, res.data);
        }).catch(function (error) {
            console.log('请求失败：', error);
            if(/status code 401/.test(error)){
                console.log('sessionId失效')
                Toast.fail('sessionId');
                // goNativeLogin();
            }else {
                // Toast.fail(error);
                reject(error);
            }
        });
    })
}

export default Axios
