<template>
  <div class="payPage">
    <van-skeleton title :row="25" :loading="loading">
      <div class="nav">{{ state.orderInfo.paymentName || "Payment" }}</div>
      <div class="nav fixed">
        {{ state.orderInfo.paymentName || "Payment" }}
      </div>
      <div class="wrap">
        <div class="card">
          <div class="amt">
            Amount Payable <span class="n"></span
            ><span class="s"
              >₹{{
                state.orderInfo.preferentialAmount
                  ? (state.orderInfo.amount * 100 -
                      state.orderInfo.preferentialAmount * 100) /
                    100
                  : state.orderInfo.amount
              }}</span
            >
          </div>
          <div
            class="yh"
            v-if="
              state.orderInfo.preferentialAmount &&
              state.orderInfo.preferentialAmount * 1 > 0
            "
          >
            <div class="items">
              <span>Order Amount </span
              ><span class="yj">₹{{ state.orderInfo.amount }}</span>
            </div>
            <div class="items">
              <span style="color: red">Discount </span
              ><span class="xyh"
                >- ₹{{ state.orderInfo.preferentialAmount }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <div class="bk">
            <img src="@/assets/img/pay/paytm.png" alt="" class="icon" />
            <van-button type="primary" size="small" @click="openApp('paytm')"
              >Click To Pay</van-button
            >
            <img src="@/assets/img/pay/toolfk.jpg" alt="" class="sh" />
          </div>
        </div>
      </div>

      <van-divider class="divider"
        ><span style="color: #1989fa; font-weight: bold">OR</span></van-divider
      >
      <div class="bottomInfo">
        <div class="tip">
          Use Wallet Scan Code To Pay
          <!-- <a style="color:#1989fa">(Recommend)</a> -->
        </div>
        <div class="tip3">
          If Use PhonePe Scan QR code to Pay , You must copy the 12-digit UTR ,
          Then Paste and Submit
        </div>
        <div class="qrCode" v-if="state.QRImgUrl">
          <img :src="state.QRImgUrl" />
        </div>
        <div class="tip2">
          Do not use the same QR code to pay multiple times
        </div>
        <div class="apps">
          <div class="tip">Quick Open App After Screenshot</div>
          <div class="icons">
            <div class="blk">
              <img
                src="@/assets/img/pay/1.png"
                alt=""
                @click="openApp('n_phonepe')"
              />
            </div>
            <div class="blk">
              <img
                src="@/assets/img/pay/4.png"
                alt=""
                @click="openApp('n_gpay')"
              />
            </div>
            <div class="blk">
              <img
                src="@/assets/img/pay/2.png"
                alt=""
                @click="openApp('n_paytm')"
              />
            </div>
            <div class="blk">
              <img
                src="@/assets/img/pay/5.png"
                alt=""
                @click="openApp('n_upi')"
              />
            </div>
          </div>
        </div>
        <div class="bomLine">
          <div class="line1"></div>
          <div class="line2"></div>
        </div>
      </div>

      <van-dialog
        v-model:show="state.showPopup"
        width="280px"
        title="Notice"
        @confirm="successConnfirm"
        show-cancel-button
        :showCancelButton="false"
        confirmButtonText="Confirm"
        :close-on-click-overlay="true"
      >
        <span
          style="
            padding: 10px;
            width: 240px;
            display: block;
            margin: auto;
            line-height: 25px;
            fontweight: 700;
          "
        >
          If Use PhonePe to Pay
          <br />
          You must copy 12-digit UTR
          <br />
          Then Paste and Submit
          <br />
        </span>

        <img
          style="width: 100%; display: bl0ck; margin: auto"
          src="@/assets/img/pay/tips12.jpg"
          alt=""
        />
      </van-dialog>

      <Utypayment />

      <div class="bomTips"></div>
      <div class="bomTips fixed">
        <van-notice-bar
          :scrollable="false"
          :left-icon="dun"
          color="#ffffff"
          background="rgb(76, 147, 220)"
        >
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
          >
            <van-swipe-item v-for="item in state.scrollList" :key="item">{{
              item
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
    </van-skeleton>
    <div class="overlay" v-if="apiLoading">
      <van-loading size="28px" vertical type="spinner" color="#1989fa"
        >Loading...</van-loading
      >
    </div>
  </div>
</template>
  
<script setup>
import {
  ref,
  reactive,
  onMounted,
  toRefs,
  onUnmounted,
  watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import QRCode from "qrcode";
import { Toast } from "vant";
import Axios from "@/axios";
import { appUrl } from "@/common/js/utils.js";
import dun from "@/assets/img/pay/dun.png";
import { decrypt } from "@/common/js/utils.js";
import Utypayment from "./utrpayment.vue";
// import { Tab, Tabs } from "vant";
let interVal;
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const apiLoading = ref(false);
const isShake = ref(false);
let state = reactive({
  showPopup: false,
  QRImgUrl: "",
  orderInfo: {},
  awakenInfo: {},
  showQr: false,
  id: route.query.id, // || 'DS230509215857C019',
  scrollList: ["Dear Member , 100% Secure Payment Powered"],
});

onMounted(async () => {
  document.title = "DragonPayment";
  window.globalData = "once";
  if (route.query.token) {
    localStorage.setItem("dragonsign", route.query.token);
    window.location.href = `${window.location.origin}/qrpay?id=${state.id}`;
  }
  //clear cache
  sessionStorage.setItem("checkOutData", "");
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    initOrder();
  }, 500);
  // setTimeout(()=>{
  //    getQRcode()
  // },600)
});

//openApp
const openApp = (type) => {
  if (type === "paytm") {
    console.log(state.awakenInfo.paytm);
    window.location.href = state.awakenInfo?.paytm;
  } else if (type == "n_paytm") {
    window.location.href = state.awakenInfo.n_paytm;
  } else if (type == "n_phonepe") {
    state.showPopup = true;
    // window.location.href = state.awakenInfo.n_phonepe
  } else if (type == "n_gpay") {
    window.location.href = state.awakenInfo.n_gpay;
  } else if (type == "n_upi") {
    window.location.href = state.awakenInfo.n_upi;
  }
  //  if (type === 'paytm') {
  //   if(state.orderInfo.ptmupiInfo == null || state.orderInfo.ptmupiInfo == undefined){
  //    window.location.href = appUrl[type] + '?' + state.orderInfo.upiInfo
  //   }else{
  //     window.location.href = appUrl['paytmw'] + '?' + state.orderInfo.ptmupiInfo
  //   }
  // } else {
  //   window.location.href = appUrl[type]
  // }
};

// 弹窗点击确定的时候触发的方法
const successConnfirm = () => {
  window.location.href = state.awakenInfo?.n_phonepe;
  console.log("state.awakenInfo?.n_phonepe", state.awakenInfo?.n_phonepe);
};

const initOrder = async function (type) {
  try {
    if (type !== "loop") {
      apiLoading.value = true;
    }
    let res = await Axios("post", "/safe/pay", {
      id: state.id,
    });

    if (res.code === 1) {
      res.data = JSON.parse(decrypt(res.data, state.id));
      state.orderInfo = res.data;
      state.awakenInfo = res.data.cashier;
      apiLoading.value = false;

      //judge by status 0:未支付,1:支付成功,2:已超时 -------  那这里就改成 等于 1 的时候就是支付成功，才跳转到success
      if ([1].includes(res.data.status)) {
        sessionStorage.setItem("checkOutData", JSON.stringify(res.data));
        router.push(`/checkout?id=${state.id}&type=success`);
      } else {
        setTimeout(() => {
          queryOrder();
        }, 6000);
        getQRcode(res.data.cashier.qrcode);
      }
    } else {
      apiLoading.value = false;
      router.push("/expired");
    }
  } catch (error) {
    apiLoading.value = false;
  }
};

//quert api
const queryOrder = async () => {
  try {
    let res = await Axios("post", "/safe/query", {
      id: state.id,
    });
    if (res.code === 1) {
      res.data = JSON.parse(decrypt(res.data, state.id));
      if ([0].includes(res.data.status)) {
        if (res.data.upi === state.orderInfo.upi) {
          setTimeout(() => {
            queryOrder();
          }, 6000);
        } else {
          initOrder("loop");
        }
      }
      if ([1].includes(res.data.status)) {
        router.push(`/checkout?id=${state.id}&type=success`);
      }
    }
  } catch (error) {
    //
  }
};

const getQRcode = (qrcode) => {
  QRCode.toDataURL(
    qrcode,
    { errorCorrectionLevel: "L", margin: 2, width: 180 },
    (err, url) => {
      if (err) throw err;
      state.QRImgUrl = url;
    }
  );
};

onUnmounted(() => {
  if (interVal) {
    clearInterval(interVal);
  }
});
</script>
  
<style scoped lang="less">
@keyframes aniDh {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.3);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.payPage {
  font-size: 14px;
  width: auto;
  background: #fef9ff;
  height: 100%;
  position: relative;

  .nav {
    font-size: 16px;
    font-weight: bold;
    color: #fef9ff;
    background: #fef9ff;

    // border-radius: 0 0 10px 10px;
    padding: 10px 0;
    text-align: center;

    &.fixed {
      position: fixed;
      color: #ffffff;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: #566aff;
      // background: linear-gradient(to right, rgb(74,157,246), #0c77f9);
    }
  }

  .wrap {
    padding: 20px 15px;
    background: #ffffff;
    background-image: linear-gradient(to bottom right, #566aff, #a7cbf3);
    border-radius: 0 0 40% 40%;
    padding-bottom: 7px;

    .card {
      padding: 16px 0;
      border-radius: 10px;
      background-color: #fff;
    }
  }

  .amt {
    font-size: 15px;
    font-weight: bold;
    padding: 0 30px;

    span.n {
      font-size: 28px;
    }

    span.s {
      font-size: 28px;
      margin-left: 5px;
      color: rgb(12, 119, 249);
    }
  }

  .yh {
    font-size: 15px;
    padding: 0 30px;
    font-weight: 600;
    margin-top: 10px;

    .items {
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      color: gray;

      .yj {
        text-decoration: line-through;
      }

      .xyh {
        color: red;
      }
    }
  }

  .list {
    margin: 15px;

    .item {
      .tl {
        color: rgb(12, 119, 249);
        font-weight: bold;
      }

      .bk {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 15px;
        background: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 4px 6px #cccccc;
        height: 30px;
        position: relative;

        .icon {
          height: 16px;
        }

        button {
          font-weight: bold;
          padding: 0 10px;
          height: 24px;
          font-size: 14px;
        }

        .sh {
          position: absolute;
          right: 15px;
          bottom: -25px;
          width: 41px;
          height: 41px;
          -webkit-animation-name: aniDh;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
        }
      }
    }
  }

  .divider {
    margin: 12px 0;
  }

  .bottomInfo {
    text-align: center;
    background: #ffffff;
    margin: 12px 15px;
    border-radius: 15px;
    box-shadow: 1px 1px 10px rgb(216, 230, 248);
    color: #000000;
    position: relative;

    .tip {
      padding-top: 10px;
      font-weight: bold;
      font-size: 16px;
    }

    .tip3 {
      padding-top: 10px;
      font-size: 13px;
      font-weight: normal;
      word-break: normal;
    }

    .qrCode {
      margin-top: 10px;

      img {
        width: 180px;
        height: 180px;
      }
    }

    .tip2 {
      margin-top: 5px;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .apps {
      .tip {
        font-size: 13px;
      }

      .icons {
        display: flex;
        justify-content: space-around;
        padding: 15px 10px 30px;

        .blk {
          width: 65px;
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 1px 1px 7px #ccc;
          border-radius: 50%;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .bomLine {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .line1 {
        height: 6px;
        background: rgb(0, 186, 245);
      }

      .line2 {
        height: 6px;
        background: rgb(2, 66, 143);
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .bomTips {
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px 10px 0 0;
    font-size: 13px;
    height: 36px;
    background: none;

    :deep(.notice-swipe) {
      height: 34px;
      line-height: 34px;
    }

    :deep(.van-notice-bar) {
      border-radius: 10px 10px 0 0;
      width: 100%;
      text-align: left;
    }

    &.fixed {
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      background: rgb(76, 147, 220);
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.utrInfo {
  .tl {
    background: #fef9ff;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;
    color: #045a8a;
  }

  .content {
    padding: 20px 0;

    .bt {
      font-weight: 600;
      text-align: center;
      color: #666666;
    }

    .time {
      width: 100px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      background: #fef9ff;
      border-radius: 20px;
      padding: 10px 10px 5px;
      align-items: center;
      justify-content: center;

      &.shake {
        animation: shake 800ms ease-in-out infinite;
      }

      :deep(.van-count-down) {
        display: flex;

        .block {
          color: #045a8a;
          font-weight: 600;
          font-size: 18px;
          text-align: center;

          .mc {
            font-weight: normal;
            font-size: 12px;
            color: gray;
          }
        }

        .colon {
          font-weight: 600;
          padding: 0 5px;
        }
      }
    }

    .btn {
      background: #0c77f9;
      font-weight: 600;
      color: #ffffff;
      width: 100px;
      height: 38px;
      line-height: 38px;
      border-radius: 8px;
      text-align: center;
      margin: 0 auto 20px;
    }
  }

  .bom {
    background: #fff5d5;
    color: #bc6a06;
    font-size: 13px;
    padding: 5px 15px;
    text-align: center;
  }
}

.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0000001f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goQR {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 83%;
  // transform: translateY(-50%);

  .breathing-circle {
    position: relative;
    width: 20px;
    height: 80px;
    background-color: #007bff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    animation: breathe 2s infinite alternate;
    font-size: 12px;
    font-weight: 600;
    writing-mode: vertical-rl;
    /* 文本竖直排列 */
    text-align: center;
    /* 文本居中 */
    line-height: 22px;
    color: #fff;
  }

  .btnStyles {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    // background: red;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // padding-left: 30px;
  }

  @keyframes breathe {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }
}
</style>
  